import React, { useCallback, useEffect, useMemo, useState } from "react";

import { GoogleMap, Marker } from "@react-google-maps/api";
import clsx from "clsx";

import Modal from "~/components/Common/Modal";
import Polygon from "~/components/Sectors/Polygon";
import AddressSearch from "~/components/tools/AddressSearch";
import sectorsAPI from "~/utils/api/v2/places";
import { LOCALITY_CONFIG } from "~/utils/constants/sectors";
import type { AddressData } from "~/utils/interfaces/Address";
import type { Locale } from "~/utils/interfaces/Locale";
import type { Sector } from "~/utils/interfaces/Places";
import { removeAccents } from "~/utils/texts";
import { Switch } from "antd";

const containerStyle = {
  width: "100%",
  height: "100%",
};

type SectorSelectProps = {
  country: Locale;
  selectedRegion: string;
  selectedSector: Sector | null;
  onSetSelectedSector: (value: Sector | null) => void;
};

const SectorSelect = ({ country, selectedRegion, selectedSector, onSetSelectedSector }: SectorSelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [isOperative, setIsOperative] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressData | null>(null);
  const [selectedInput, setSelectedInput] = useState<"sector" | "address" | null>(null);

  const filteredSectorIds = useMemo(() => {
    const loweredSearch = removeAccents(currentSearch).toLowerCase();
    if (currentSearch) {
      const _filteredSectors = sectors.filter(({ name }) => removeAccents(name).toLowerCase().includes(loweredSearch));
      return _filteredSectors.map(({ id }) => id);
    }
    return [];
  }, [currentSearch, sectors]);

  const handleSectorSelect = (sectorId: string) => {
    if (selectedSector && selectedSector.id === sectorId) {
      onSetSelectedSector(null);
    } else {
      onSetSelectedSector(sectors.find(({ id }) => id === sectorId) || null);
    }
  };

  const handleCancel = () => {
    onSetSelectedSector(null);
    setSelectedAddress(null);
    setCurrentSearch("");
  };

  const fetchSectors = useCallback(async () => {
    if (!country) return;
    const sectors = await sectorsAPI.list(country, isOperative);
    if (sectors) {
      setSectors(sectors);
    }
  }, [country, isOperative]);

  useEffect(() => {
    if (!country) return;

    fetchSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, isOperative]);

  const searchInputs = (
    <div className="flex gap-x-2">
      <input
        value={currentSearch}
        type="text"
        className={clsx(
          "w-1/2 p-2 border border-examedi-gray-3 rounded-md",
          "transition-all duration-200 ease-in-out",
          selectedInput === "sector" && "w-3/4",
        )}
        placeholder="Buscar por nombre de sector"
        onFocus={() => {
          setSelectedInput("sector");
          setSelectedAddress(null);
        }}
        onBlur={() => setSelectedInput(null)}
        onChange={(e) => {
          if (!currentSearch) setCurrentSearch(e.target.value.trim());
          else setCurrentSearch(e.target.value);
        }}
      />
      <AddressSearch
        countryCode={country}
        placeholder="Buscar por dirección"
        className="w-full py-2 px-4 rounded-md border border-examedi-gray-3"
        containerClassName={clsx(
          "w-1/2",
          "transition-all duration-200 ease-in-out",
          selectedInput === "address" && "w-3/4",
        )}
        onFocus={() => {
          setSelectedInput("address");
          setCurrentSearch("");
        }}
        onBlur={() => setSelectedInput(null)}
        callback={(addressData: AddressData) => setSelectedAddress(addressData)}
      />
    </div>
  );

  return (
    <div className={clsx("w-1/2", !!selectedSector && "w-full")}>
      <div className="w-full flex gap-x-4 items-end justify-between">
        <button
          className={clsx(
            "w-full px-3.5 py-2.5",
            "text-center text-examedi-white-pure",
            "bg-examedi-blue-strong hover:bg-examedi-blue-strong-50",
            "rounded-md",
            "transition-all duration-100 ease-in-out",
            !!selectedSector && "!w-1/2",
          )}
          onClick={() => setIsOpen(true)}
        >
          {selectedSector ? "Elegir otro sector" : "Elegir sector"}
        </button>
        {!!selectedSector && (
          <div className="w-1/2 text-xl leading-6 text-examedi-gray-2">
            <p className="text-sm">Sector elegido:</p>
            <p className="py-2.5 font-medium">{selectedSector.name}</p>
          </div>
        )}
      </div>
      <Modal
        header="Elige un sector"
        content={
          <div className="w-full h-120 flex flex-col gap-y-3">
            <div className="flex justify-center">
              Normal
              <Switch
                className="mx-3"
                checked={isOperative}
                onChange={(value) => setIsOperative(value)}
              />
              Operativos
            </div>
            {searchInputs}
            {/* @ts-expect-error */}
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={LOCALITY_CONFIG[country][selectedRegion]?.center}
              zoom={LOCALITY_CONFIG[country][selectedRegion]?.zoom}
            >
              {sectors?.map((sector) => (
                <Polygon
                  key={sector.id}
                  sector={sector}
                  selectedId={null}
                  country={country}
                  selectedRegion={selectedRegion}
                  isHighlighted={filteredSectorIds.includes(sector.id)}
                  forceHighlight={!!currentSearch}
                  onSelect={handleSectorSelect}
                />
              ))}
              {!!selectedAddress && (
                <>
                  {/* @ts-expect-error */}
                  <Marker
                    position={{ lng: selectedAddress.longitude, lat: selectedAddress.latitude }}
                    animation={google.maps.Animation.DROP}
                  />
                </>
              )}
            </GoogleMap>
          </div>
        }
        footer={
          <div className="flex justify-end space-x-4">
            {!!selectedSector && (
              <div className="w-full flex">
                <p>
                  Haz seleccionado el sector de <span className="font-medium">{selectedSector.name}</span>
                </p>
              </div>
            )}
            <button
              className="px-4 py-2 rounded shadow text-white bg-examedi-red-strong hover:bg-examedi-red-light"
              onClick={() => {
                handleCancel();
                setIsOpen(false);
              }}
            >
              Cancelar
            </button>
            <button
              className="px-4 py-2 rounded shadow text-white bg-examedi-blue-strong hover:bg-examedi-blue-strong-75"
              onClick={() => setIsOpen(false)}
            >
              Confirmar
            </button>
          </div>
        }
        isOpen={isOpen}
        onSetIsOpen={(value: boolean) => setIsOpen(value)}
      />
    </div>
  );
};

export default SectorSelect;
